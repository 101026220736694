import React, { useEffect } from 'react';
import _ from 'lodash';
import { func, node, shape, string } from 'prop-types';
import styled from '@emotion/styled';
import {
  AuthLayout,
  FormItem,
  Label,
  Input,
  Icon,
} from '@bitsoflove/pattyn-360/lib/components';
import logo from '@bitsoflove/pattyn-360/lib/assets/images/pattyn-logo.svg';
import decoration from '@bitsoflove/pattyn-360/lib/assets/images/login-decoration.svg';

import { rgba } from 'polished';
import { Field, Formik, ErrorMessage } from 'formik';
import Link from 'next/link';
import { STATE_LOADING, useAuth } from '~/api/AuthProvider';
import { AUTH_ROUTES } from '~/config/auth';

const ContentWrapper = styled.div`
  position: relative;
`;
const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#eef2f6', 0.9)};
`;
const StyledLightLink = styled.a`
  font-weight: bold;
  font-size: 0.85rem;
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.mediumGrey};
`;

function AuthPageLayout({
  children,
  title,
  message,
  initialValues,
  onSubmit,
  validate,
  link,
}) {
  const { state, clear } = useAuth();
  const isLoading = state === STATE_LOADING;

  useEffect(clear, []);

  return (
    <div style={{ height: '100vh' }}>
      <AuthLayout
        title={
          <>
            Pattyn <strong>360</strong>
          </>
        }
        subtitle="Login to continue"
        formTitle={title}
        logoImage={logo}
        decorationImage={decoration}
        footer={
          <StyledLightLink href="http://old.pattyn360.com/">
            Longtime Pattyn 360 user? Our previous portal is still available{' '}
            <u>here</u>.
          </StyledLightLink>
        }
      >
        <ContentWrapper>
          {message}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {formik => {
              return (
                <form onSubmit={formik.handleSubmit}>{children(formik)}</form>
              );
            }}
          </Formik>
          <br />
          {link}

          {isLoading && (
            <LoaderWrapper>
              <Icon icon="progress" spin />
            </LoaderWrapper>
          )}
        </ContentWrapper>
      </AuthLayout>
    </div>
  );
}

AuthPageLayout.Field = ({ formik, name, type, placeholder, label }) => {
  return (
    <FormItem
      id={`account-${name}`}
      direction="column"
      hasError={!!_.get(formik.errors, name)}
    >
      <Label>{label}</Label>
      <Field as={Input} type={type} name={name} placeholder={placeholder} />
      <ErrorMessage name={name} />
    </FormItem>
  );
};
AuthPageLayout.Field.propTypes = {
  formik: shape({ errors: shape({}) }).isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
  label: string.isRequired,
  type: string.isRequired,
};

AuthPageLayout.propTypes = {
  title: string,
  children: func.isRequired,
  message: node,
  initialValues: shape({
    email: string,
    password: string,
  }),
  onSubmit: func.isRequired,
  validate: func.isRequired,
  link: node,
};
AuthPageLayout.defaultProps = {
  title: 'Login',
  message: null,
  initialValues: {},
  link: (
    <Link href={AUTH_ROUTES.login}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>Return to login</a>
    </Link>
  ),
};

export default AuthPageLayout;
